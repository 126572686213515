import * as React from "react";
import { observer } from "mobx-react";

import { useLocalization } from "@shared-ui/localization-context";
import { EGDSCard, EGDSCardContentSection, EGDSCardLink } from "@egds/react-core/cards";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { EGDSIcon } from "@egds/react-core/icons";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { EGDSHeading, EGDSText } from "@egds/react-core/text";

import { HotelWizardState } from "stores/wizard/state";
import { travelersMetadata, travelersSummary } from "components/shared/TravelersField/utils";
import { getCityWithState } from "../utils/SearchSummaryCardUtils";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

export interface SearchSummaryCardProps {
  context: ExtendedContextStore;
  hotelWizardState: HotelWizardState;
  onExpand: () => void;
}

export const SearchSummaryCard: React.FC<SearchSummaryCardProps> = observer(
  ({ context, hotelWizardState, onExpand }) => {
    const { formatDate, formatText } = useLocalization();
    const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();
      onExpand();
    };

    // Example: Mon, Sep 14
    const summaryDateFormat = { skeleton: "MMMEd" };

    const formattedStartDate = formatDate(hotelWizardState.date.start, summaryDateFormat);
    const formattedEndDate = formatDate(hotelWizardState.date.end, summaryDateFormat);

    const dates = `${formattedStartDate} - ${formattedEndDate}`;

    // context.searchContext.location is populated when LOB is hotel, car, or package
    // context.searchContext.destination is populated when LOB is flight
    const location = context.searchContext.location || context.searchContext.destination;
    const locationName = getCityWithState(location);

    const { numOfAdults, numOfChildren } = travelersMetadata(hotelWizardState.travelers.hotel);
    const travelers = travelersSummary(
      hotelWizardState.config.travelers,
      hotelWizardState.travelers.hotel.rooms,
      numOfAdults + numOfChildren
    );

    const a11yLabel = formatText(
      "hotwire.wizard.summary.a11yLabel",
      locationName,
      formattedStartDate,
      formattedEndDate,
      travelers
    );

    return (
      <EGDSCard border>
        <EGDSLayoutFlex>
          <EGDSLayoutFlexItem alignSelf="center" grow={0}>
            <EGDSCardContentSection padded>
              <EGDSIcon name="search" />
            </EGDSCardContentSection>
          </EGDSLayoutFlexItem>
          <EGDSLayoutFlexItem grow={1}>
            <EGDSSpacing padding={{ blockstart: "three", blockend: "three", inlineend: "three" }}>
              <EGDSCardContentSection padded={false}>
                <EGDSHeading tag="h2" size={5} overflow="truncate">
                  {locationName}
                </EGDSHeading>
                <EGDSLayoutFlex className="SearchSummaryCard-criteria" wrap="wrap">
                  <EGDSLayoutFlexItem>
                    <EGDSSpacing margin={{ inlineend: "two" }}>
                      <div id="search-summary-card-date-fields">
                        <EGDSText size={200} overflow="truncate">
                          {dates}
                        </EGDSText>
                      </div>
                    </EGDSSpacing>
                  </EGDSLayoutFlexItem>
                  <EGDSLayoutFlexItem>
                    <EGDSText size={200} overflow="truncate">
                      {travelers}
                    </EGDSText>
                  </EGDSLayoutFlexItem>
                </EGDSLayoutFlex>
              </EGDSCardContentSection>
            </EGDSSpacing>
          </EGDSLayoutFlexItem>
        </EGDSLayoutFlex>
        <EGDSCardLink>
          <a href="#" onClick={onClick}>
            {a11yLabel}
          </a>
        </EGDSCardLink>
      </EGDSCard>
    );
  }
);
