import * as React from "react";
import { EGDSCheckbox } from "@egds/react-core/checkbox";
import { EGDSExpandoLink } from "@egds/react-core/expando";
import { EGDSLinkSizes } from "@egds/react-core/link";
import { EGDSLayoutGrid, EGDSLayoutGridItem, EGDSLayoutConditionalGridSpan } from "@egds/react-core/layout-grid";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { locKeys } from "./l10n";
import { useLocalization } from "@shared-ui/localization-context";
import { WizardHotelFilterType } from "./WizardHotelFilterType";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { withStores } from "stores";
import { PropertyFiltersStore } from "src/stores/PropertyFiltersStore";
import { ExtendedContextStore } from "src/typings/flexFramework/FlexDefinitions";
import { EGDSSpacing } from "@egds/react-core/spacing";

const WizardHotelFilterList = [
  WizardHotelFilterType.businessFriendly,
  WizardHotelFilterType.freeCancellation,
  WizardHotelFilterType.familyFriendly,
  WizardHotelFilterType.breakfastIncluded,
];
const WizardHotelFilterListMobile = [
  WizardHotelFilterType.businessFriendly,
  WizardHotelFilterType.familyFriendly,
  WizardHotelFilterType.freeCancellation,
  WizardHotelFilterType.breakfastIncluded,
];

interface WizardHotelPWAFilterProps {
  checkboxFilterChecks: object;
  changeFormProperties?: (event: React.ChangeEvent<HTMLFormElement>) => void;
  advancedOptionsFilterVisible?: boolean;
  propertyFilters: PropertyFiltersStore;
  context?: ExtendedContextStore;
  popularFiltersColSpan?: EGDSLayoutConditionalGridSpan;
}

export const WizardHotelPWAFilter = withStores("propertyFilters")((props: WizardHotelPWAFilterProps) => {
  const { checkboxFilterChecks, changeFormProperties, advancedOptionsFilterVisible } = props;
  const { formatText } = useLocalization();
  const title = formatText(locKeys.filterAdvancedOptionsLink);
  const [isExpandoVisible, setIsExpandoVisible] = React.useState(Boolean(advancedOptionsFilterVisible));

  return (
    <EGDSLayoutFlex space="three">
      <EGDSExpandoLink
        size={EGDSLinkSizes.get("medium")}
        expandoTitle={title}
        isVisible={isExpandoVisible}
        onToggle={() => setIsExpandoVisible((prevIsVisible) => !prevIsVisible)}
      >
        <Viewport>
          <ViewSmall>
            <>
              {WizardHotelFilterListMobile.map(({ id, key, filterTypeKey, name, value }) => (
                <EGDSCheckbox
                  id={id}
                  name={name}
                  checked={checkboxFilterChecks[key as keyof object]}
                  onChange={changeFormProperties}
                  value={value}
                  key={key}
                >
                  {formatText(filterTypeKey)}
                </EGDSCheckbox>
              ))}
            </>
          </ViewSmall>
          <ViewMedium>
            <EGDSLayoutGrid columns={2}>
              {WizardHotelFilterList.map(({ id, key, filterTypeKey, name, value }) => (
                <EGDSLayoutGridItem key={key}>
                  <EGDSSpacing padding={{ inlineend: "six" }}>
                    <div>
                      <EGDSCheckbox
                        id={id}
                        name={name}
                        checked={checkboxFilterChecks[key as keyof object]}
                        onChange={changeFormProperties}
                        value={value}
                      >
                        {formatText(filterTypeKey)}
                      </EGDSCheckbox>
                    </div>
                  </EGDSSpacing>
                </EGDSLayoutGridItem>
              ))}
            </EGDSLayoutGrid>
          </ViewMedium>
        </Viewport>
      </EGDSExpandoLink>
    </EGDSLayoutFlex>
  );
});
