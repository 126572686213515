/**
 * Enable multiple places checkbox on hotels form, when checked triggers the "multi property" shopping in PWA by setting a query parameter in the URL: multiProp=true
 * This is managed by `wizard-hotel-pwa-v2` module (https://flexmanager.prod.expedia.com/#/moduleStore/show/255)
 */
import * as React from "react";
import { EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSCheckbox } from "@egds/react-core/checkbox";

import { useLocalization } from "@shared-ui/localization-context";

import { locKeys } from "components/flexComponents/WizardHotelPWA/components/l10n";
import { MultiplePlacesProps } from "../typings";

const { needMultiplePlaces } = locKeys;

export const MultiplePlaces = ({ disabled, hotelWizardState, isChecked, colSpan }: MultiplePlacesProps) => {
  const { formatText } = useLocalization();

  const handleCheckboxChange = () => {
    hotelWizardState.toggleMultiplePlacesCheckbox();
  };

  return (
    <EGDSLayoutGridItem colSpan={colSpan}>
      <div>
        <EGDSCheckbox
          id="multiProp"
          checked={hotelWizardState.isMultiplePlacesChecked}
          onChange={handleCheckboxChange}
          disabled={disabled}
          value={String(hotelWizardState.isMultiplePlacesChecked)}
        >
          {formatText(needMultiplePlaces)}
        </EGDSCheckbox>
      </div>
    </EGDSLayoutGridItem>
  );
};
