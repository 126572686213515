import * as React from "react";
import { useClickTracker } from "@shared-ui/clickstream-analytics-context";
import { observer } from "mobx-react";
import { EGDSCheckbox } from "@egds/react-core/checkbox";
import { HotelWizardStateProps } from "components/flexComponents/WizardHotelPWAv2/typings";
import { EGDSText } from "@egds/react-core/text";
import { HotelRatesType } from "stores/wizard/state/typings";

export interface HotelAddPackageRatesCheckboxProps extends HotelWizardStateProps {
  packageRatesTermsUrl?: string;
  percentSave?: number;
  isChecked?: boolean;
  subTabType: HotelRatesType;
}

export const HotelPackageRatesTermsCheckbox = observer(
  ({ hotelWizardState, isChecked, subTabType }: HotelAddPackageRatesCheckboxProps) => {
    const track = useClickTracker();
    const urlParameterName = "packageRates";

    if (!hotelWizardState.travelAgentPackageRatesDetail) {
      return null;
    }

    const handlePackageCheckboxChange = () => {
      const rfrr = "WizardHotelPWAv2.TermsConditions_PackageRates.click";
      const linkName = "RFRR Action Link";
      track(rfrr, linkName);

      hotelWizardState.togglePackageRatesTermsCheckbox();
    };

    return (
      <div>
        <EGDSText size={300} weight="bold" spacing="six">
          {hotelWizardState.travelAgentPackageRatesDetail.heading ?? ""}
        </EGDSText>
        <EGDSCheckbox
          data-stid="taap-package-rates"
          id={urlParameterName}
          data-testid={urlParameterName}
          checked={!!isChecked}
          onChange={handlePackageCheckboxChange}
          value={(!!isChecked && "true") || "false"}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: hotelWizardState.travelAgentPackageRatesDetail.termsAndConditions,
            }}
          />
        </EGDSCheckbox>
      </div>
    );
  }
);
